import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SeqHome from './LandingPage/SeqHome.js';
import { ChakraProvider } from '@chakra-ui/react';
import AppPage from './routes/AppPage.js';
import Pricing from './routes/Pricing.js';
import DocumentView from './routes/DocumentView.js';
import ScrollToTop from './experiment/ScrollToTop.js';

function App() {
  return (
    <div className="App">
      <ChakraProvider>
        <Router basename='/'>
        <ScrollToTop />
          <Routes>
            <Route path="/" element={<SeqHome/>}/>
            <Route path="/appPage" element={<AppPage/>} />
            <Route path="/csPricing" element={<Pricing/>} />
            <Route path="/aaPricing" element={<Pricing/>} />
            <Route path="/tos" element={<DocumentView/>} />
            <Route path="/dpp" element={<DocumentView/>} />
          </Routes>
        </Router>
      </ChakraProvider>
    </div>
  );
}

export default App;
