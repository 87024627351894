import React from 'react';
import { HStack, Text } from '@chakra-ui/react';
import { PRIMARY_COLOR, PRIMARY_FONT } from '../constant';

function PriceItemHeader(props) {
  return (
    <HStack w="80%" border={"1px solid " + PRIMARY_COLOR} mt="50px" fontFamily={PRIMARY_FONT} p="20px" bg={PRIMARY_COLOR} borderTopRadius={"5px"} display={{base: "none", sm:"none",md: "none", lg: "flex", xl: "flex"}}>
        <Text w="15%" as="b" borderRight={"1px solid " + PRIMARY_COLOR} color={"#ffffff"}>Type of Entity</Text>
        <Text w="55%" as="b" borderRight={"1px solid " + PRIMARY_COLOR}  color={"#ffffff"}>Description of Service</Text>
        <Text w="15%" as="b" borderRight={"1px solid " + PRIMARY_COLOR}  color={"#ffffff"}> Unit of Measure </Text>
        <Text w="15%" as="b"  color={"#ffffff"}>{"Total Price (SGD)"}</Text>
    </HStack>
  )
}

export default PriceItemHeader
