import { SEND_EMAIL_URL } from "../constant";

export const sendEmail = (onSuccess, onFailure, details) => {
    const headers = {
        'Content-Type': 'application/json'
    }
    console.log(SEND_EMAIL_URL);
    const body = JSON.stringify(details);
    fetch(SEND_EMAIL_URL, {method:"POST",headers, body:body})
    .then((res) => {
        onSuccess(res);
    })
    .catch((err) => {
        onFailure();
        console.log(err);
    })
};