import React from 'react';
import { Card, HStack, Text } from '@chakra-ui/react';
import { PRIMARY_COLOR } from '../constant';
import MobilePriceItem from './MobilePriceItem';

function PriceItem(props) {
    const {
        isLastElement,
        element
    } = props;

    const {
        entityType,
        typeOfService,
        unitOfMeasure,
        totalPrice
    } = element;

  return (
    <>
    <HStack w="80%" borderBottom={(!isLastElement)?"1px solid " + PRIMARY_COLOR:""} p="20px" display={{base: "none", sm:"none",md: "none", lg: "flex", xl: "flex"}}>
        <Text w="15%">{entityType}</Text>
        <Text w="55%">{typeOfService}</Text>
        <Text w="15%">{unitOfMeasure}</Text>
        <Text w="15%" as="b">{totalPrice}</Text>
    </HStack>
    <MobilePriceItem element={element}/>    
    </>
  )
}

export default PriceItem;