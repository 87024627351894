import React, { useEffect, useState } from 'react';
import { ChakraProvider, Box, Flex, Avatar, Heading, SimpleGrid, Card, CardBody, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure, Select, InputGroup, Input, Textarea, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

function AppPage() {

  const navigate = useNavigate();
  const goToHome = () => {
    navigate('/');
  };
  const [selectedCard, setSelectedCard] = useState(null);
  const [s1, setS1] = useState("");
  const [s2, setS2] = useState("");
  const [details, setDetails] = useState("");
  const [showRequestCard, setShowRequestCard] = useState(false);

  useEffect(()=>{
    if(details){
        setShowRequestCard(true);
    }else{
        setShowRequestCard(false);
    }
  },[details]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleCardClick = (cardId) => {
    setSelectedCard(cardId);
    onOpen();
  };

  return (
    <ChakraProvider>
      {/* Header */}
      <Box bg="gray.100" p={4}>
        <Flex alignItems="center">
          <Avatar name="MK" src="MK" />
          <Heading ml={4} size="md">Sequence</Heading>
          <Button ml={4} size="md" varian="link" onClick={()=>{goToHome()}}>Go Home</Button>
        </Flex>
      </Box>

      {/* Main Content with Cards */}
      <Box p={8}>
        <SimpleGrid columns={[1, null, 3]} spacing="40px">
          <Card onClick={() => handleCardClick("Request Service")} cursor="pointer">
            <CardBody>
              <Heading size="md">Request Service</Heading>
            </CardBody>
          </Card>
          {(showRequestCard)?
            <Card onClick={() => handleCardClick("Service")} cursor="pointer">
            <CardBody>
              <Heading size="md">Request</Heading>
              <Text size="md">{"Service Type: " + s1}</Text>
              <Text size="md">{"Service Sub Type: " + s2}</Text>
              <Text size="md">{"Service Details: " + details}</Text>
            </CardBody>
          </Card>
          :null}
        </SimpleGrid>
      </Box>

      {/* Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selectedCard} Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Select placeholder='Select service' my="20px" onChange={(e)=>{console.log(e); setS1(e.target.value)}}>
                <option value='Secreterial'>Secreterial</option>
                <option value='Audit'>Audit</option>
                <option value='HR'>HR</option>
                <option value='Consultancy'>Consultancy</option>
            </Select>
            <Select placeholder='Select service' my="20px" onChange={(e)=>{setS2(e.target.value)}}>
                <option value='Secreterial Service 1'>Secreterial Service 1</option>
                <option value='Secreterial Service 2'>Secreterial Service 2</option>
                <option value='Secreterial Service 3'>Secreterial Service 3</option>
                <option value='Secreterial Service 4'>Secreterial Service 4</option>
            </Select>
            <InputGroup mb="10px">
                <Textarea placeholder='Service Details' focusBorderColor="#2b6cb0" onChange={(e) => {setDetails(e.target.value)}}/>
              </InputGroup>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Submit
            </Button>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
}

export default AppPage;
