import React, { useEffect, useState } from 'react';
import { Box, Center, Heading, Text, VStack } from '@chakra-ui/react';
import { AA_DETAILS, CS_DETAILS, PRIMARY_COLOR, PRIMARY_FONT } from '../constant';
import HeaderExp from '../experiment/HeaderExp';
import Footer from '../LandingPage/Footer';
import PriceItemHeader from '../experiment/PriceItemHeader';
import PriceItem from '../experiment/PriceItem';
import { useLocation } from 'react-router-dom';
import { CS_PRICING } from '../csPricngConstant';
import { AA_PRICING } from '../aaPricingConstant';

function Pricing(props) {
  const location = useLocation();
  const [pricing, setPricing] = useState(CS_PRICING);
  const [title, setTitle] = useState("Corporate Secretarial Pricing");
  const [details, setDetails] = useState(CS_DETAILS);
  useEffect(() => {
    if(location.pathname === "/csPricing"){
      setPricing(CS_PRICING);
      setTitle("Corporate Secretarial Pricing");
      setDetails(CS_DETAILS);
    }else if(location.pathname === "/aaPricing"){
      setPricing(AA_PRICING);
      setTitle("Accounting & Auditing Pricing");
      setDetails(AA_DETAILS);
    }
  },[]);

  return (
    <Box>
      <HeaderExp showMenu={false}/>
      <Center>
      <VStack w="1281px" fontFamily={PRIMARY_FONT} h="100%" boxShadow="0 0 20px rgba(0, 0, 0, 0.25)" mb="30px" mt="100px" borderRadius="10px" display={{base: "none", sm:"none",md: "none", lg: "flex", xl: "flex"}}>
        <Center>
            <Heading mt="50px">{title}</Heading>
        </Center>
        {details.map((element,index) => {
          return(<>
            <Text w="80%" mt={index === 0?"20px":"10px"} fontFamily={PRIMARY_FONT} textAlign="justify" key={index}>{element}</Text>
          </>)
        })}
        <PriceItemHeader fontFamily={PRIMARY_FONT}/>
        <>{
          pricing.map((element, index) => {
            return(
              <PriceItem element={element} key={index} isLastElement={pricing.length === (parseInt(index) + 1)}/>
            )
          })
        }</>
      </VStack>
      </Center>
      <VStack fontFamily={PRIMARY_FONT} w="100%" boxShadow="0 0 20px rgba(0, 0, 0, 0.25)" mb="30px" mt="100px" borderRadius="10px" display={{base: "flex", sm:"flex",md: "flex", lg: "none", xl: "none"}}>
        <Center>
            <Heading as="h5" mt="50px" fontFamily={PRIMARY_FONT} color={PRIMARY_COLOR}>{title}</Heading>
        </Center>
        {details.map((element,index) => {
          return(<>
            <Text w="90%" mt={index === 0?"20px":"10px"} textAlign="justify" key={index}>{element}</Text>
          </>)
        })}
        <Center>
            <Heading as="h6" my="20px" fontFamily={PRIMARY_FONT} color={PRIMARY_COLOR}>{"Pricing"}</Heading>
        </Center>
        <>{
          pricing.map((element, index) => {
            return(
              <PriceItem element={element} key={index} isLastElement={pricing.length === (parseInt(index) + 1)}/>
            )
          })
        }</>
      </VStack>
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Footer />
      </Box>
    </Box>
  )
}

export default Pricing
