export const CS_PRICING = [
    {
      entityType: "Local Company",
      typeOfService: "Incorporation of a Local Company (exclusive of government fees)",
      mTypeOfService: "Incorporation of a Local Company",
      mInnerDetails: "(exclusive of government fees)",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 300"
    },
    {
      entityType: "Local Company",
      typeOfService: "ACRA Fee for Incorporation of a Local Company",
      mTypeOfService: "ACRA Fee for Incorporation of a Local Company",
      mInnerDetails: "",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 315"
    },
    {
      entityType: "Branch of Foreign Company",
      typeOfService: "Registration of a Branch of a Foreign Company (exclusive of government fees)",
      mTypeOfService: "Registration of a Branch of a Foreign Company",
      mInnerDetails: "(exclusive of government fees)",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 2,400"
    },
    {
      entityType: "Branch of Foreign Company",
      typeOfService: "ACRA Fee for Registration of a Branch of a Foreign Company",
      mTypeOfService: "ACRA Fee for Registration of a Branch of a Foreign Company",
      mInnerDetails: "",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 315"
    },
    {
      entityType: "Variable Capital Company",
      typeOfService: "Incorporation of a Variable Capital Company (exclusive of government fees), including the following:- Reservation of name application- Preparation and compilation of forms (including VCR1, VCR2, VCR6, VCR7) for VCC stakeholders- Preparation of the first board resolution of the VCC- Preparation of Constitution based on the model VCC constitution- General advice in relation to the incorporation, and liaising with ACRA in relation to issues or queries",
      mTypeOfService: "Incorporation of a Variable Capital Company",
      mInnerDetails: "(exclusive of government fees), including the following:- Reservation of name application- Preparation and compilation of forms (including VCR1, VCR2, VCR6, VCR7) for VCC stakeholders- Preparation of the first board resolution of the VCC- Preparation of Constitution based on the model VCC constitution- General advice in relation to the incorporation, and liaising with ACRA in relation to issues or queries",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 3,000"
    },
    {
      entityType: "Variable Capital Company",
      typeOfService: "ACRA Fees for Incorporation of a Variable Capital Company",
      mTypeOfService: "ACRA Fees for Incorporation of a VCC",
      mInnerDetails: "",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 8,015"
    },
    {
      entityType: "Variable Capital Company",
      typeOfService: "Registration of a Sub-Fund under a Variable Capital Company (exclusive of government fees)",
      mTypeOfService: "Registration of a Sub-Fund under a VCC",
      mInnerDetails: "(exclusive of government fees)",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 500"
    },
    {
      entityType: "Variable Capital Company",
      typeOfService: "ACRA Fee for Registration of a Sub-Fund under a Variable Capital Company",
      mTypeOfService: "ACRA Fee for Registration of a Sub-Fund under a VCC",
      mInnerDetails: "",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 400"
    },
    {
      entityType: "All Entities",
      typeOfService: "Transfer In from other Corporate Service Providers",
      mTypeOfService: "Transfer In from other Corporate Service Providers",
      mInnerDetails: "",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 0"
    },
    {
      entityType: "All Companies",
      typeOfService: "Named Company Secretary",
      mTypeOfService: "Named Company Secretary",
      mInnerDetails: "",
      unitOfMeasure: "Per Year",
      totalPrice: "S$ 150"
    },
    {
      entityType: "All Entities",
      typeOfService: "Maintenance of Statutory Registers and Statutory Records",
      mTypeOfService: "Maintain Statutory Registers and Statutory Records",
      mInnerDetails: "",
      unitOfMeasure: "Per Year",
      totalPrice: "S$ 150"
    },
    {
      entityType: "Local Company",
      typeOfService: "Annual General Meeting",
      mTypeOfService: "Annual General Meeting",
      mInnerDetails: "",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 300"
    },
    {
      entityType: "Local Company",
      typeOfService: "Annual Return (exclusive of government fees)",
      mTypeOfService: "Annual Return",
      mInnerDetails: " (exclusive of government fees)",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 100"
    },
    {
      entityType: "Local Company",
      typeOfService: "ACRA Fee for Annual Return",
      mTypeOfService: "ACRA Fee for Annual Return",
      mInnerDetails: "",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 60"
    },
    {
      entityType: "All Entities",
      typeOfService: "Provision of Registered Office Address (without scanning services)",
      mTypeOfService: "Registered Office Address (without scanning services)",
      mInnerDetails: "",
      unitOfMeasure: "Per Year",
      totalPrice: "S$ 360"
    },
    {
      entityType: "All Entities",
      typeOfService: "Mail Scanning Services for Registered Office Address",
      mTypeOfService: "Mail Scanning Services for Registered Office Address",
      mInnerDetails: "",
      unitOfMeasure: "Per Year",
      totalPrice: "S$ 360"
    },
    {
      entityType: "All Companies",
      typeOfService: "Provision of Nominee Director",
      mTypeOfService: "Provision of Nominee Director",
      mInnerDetails: "",
      unitOfMeasure: "Per Year",
      totalPrice: "S$ 2,400"
    },
    {
      entityType: "All Companies with Shares",
      typeOfService: "Provision of Nominee Shareholder",
      mTypeOfService: "Provision of Nominee Shareholder",
      mInnerDetails: "",
      unitOfMeasure: "Per Year",
      totalPrice: "S$ 2,400"
    },
    {
      entityType: "Branch of Foreign Company",
      typeOfService: "Provision of Authorised Representative (for branches)",
      mTypeOfService: "Provision of Authorised Representative",
      mInnerDetails: " for branches",
      unitOfMeasure: "Per Year",
      totalPrice: "S$ 2,400"
    },
    {
      entityType: "All Companies",
      typeOfService: "Customised Constitution",
      mTypeOfService: "Customised Constitution",
      mInnerDetails: "",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 2,400"
    },
    {
      entityType: "All Companies",
      typeOfService: "Change of Business Activities (SSIC)",
      mTypeOfService: "Change of Business Activities (SSIC)",
      mInnerDetails: "",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 80"
    },
    {
      entityType: "All Companies",
      typeOfService: "Change of Registered Office Address",
      mTypeOfService: "Change of Registered Office Address",
      mInnerDetails: "",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 80"
    },
    {
      entityType: "All Companies",
      typeOfService: "Change of Financial Year End",
      mTypeOfService: "Change of Financial Year End",
      mInnerDetails: "",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 80"
    },
    {
      entityType: "All Companies",
      typeOfService: "Change of Company Name (exclusive of government fees)",
      mTypeOfService: "Change of Company Name",
      mInnerDetails: "(exclusive of government fees)",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 80"
    },
    {
      entityType: "All Companies",
      typeOfService: "ACRA Fee for Change of Company Name",
      mTypeOfService: "ACRA Fee for Change of Company Name",
      mInnerDetails: "",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 15"
    },
    {
      entityType: "All Companies",
      typeOfService: "Change of Company Type (from Private to Public or from Public to Private) (exclusive of government fees)",
      mTypeOfService: "Change of Company Type",
      mInnerDetails: "from Private to Public or from Public to Private (exclusive of government fees)",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 180"
    },
    {
      entityType: "All Companies",
      typeOfService: "ACRA Fee for Change of Company Type (from Private to Public or from Public to Private)",
      mTypeOfService: "ACRA Fee for Change of Company Type",
      mInnerDetails: "from Private to Public or from Public to Private",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 40"
    },
    {
      entityType: "All Companies",
      typeOfService: "Change in Particulars of Officers (e.g. Director, Secretary) or Auditors",
      mTypeOfService: "Change in Particulars of Officers or Auditors",
      mInnerDetails: "including Director, Secretary",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 80"
    },
    {
      entityType: "All Companies",
      typeOfService: "Change in Particulars of Members",
      mTypeOfService: "Change in Particulars of Members",
      mInnerDetails: "",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 80"
    },
    {
      entityType: "All Companies",
      typeOfService: "Addition or Amendment of Alternate Address for Officers (e.g. Director, Secretary) (exclusive of government fees)",
      mTypeOfService: "Add/Amend Alternate Address for Officers",
      mInnerDetails: "including Director, Secretary (exclusive of government fees)",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 80"
    },
    {
      entityType: "All Companies",
      typeOfService: "ACRA Fee for Addition or Amendment of Alternate Address for Officers (e.g. Director, Secretary)",
      mTypeOfService: "ACRA Fee for Alternate Address for Officers",
      mInnerDetails: "including Director, Secretary",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 40"
    },
    {
      entityType: "All Companies",
      typeOfService: "Provision of Alternate Address for Officers (e.g. Director, Secretary)",
      mTypeOfService: "Provision of Alternate Address for Officers",
      mInnerDetails: "including Director, Secretary",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 120"
    },
    {
      entityType: "All Companies",
      typeOfService: "Appointment or Resignation of Officers (e.g. Director, Secretary) or Auditors",
      mTypeOfService: "Appointment or Resignation of Officers or Auditors",
      mInnerDetails: "including Director, Secretary",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 80"
    },
    {
      entityType: "All Companies",
      typeOfService: "Removal of Officers (e.g. Director, Secretary) or Auditors",
      mTypeOfService: "Removal of Officers or Auditors",
      mInnerDetails: "",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 240"
    },
    {
      entityType: "All Companies",
      typeOfService: "EGM (Extraordinary General Meeting)",
      mTypeOfService: "EGM",
      mInnerDetails: "Extraordinary General Meeting",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 360"
    },
    {
      entityType: "All Companies",
      typeOfService: "Directors' Resolutions",
      mTypeOfService: "Directors' Resolutions",
      mInnerDetails: "",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 80"
    },
    {
      entityType: "All Companies",
      typeOfService: "Members' Resolutions",
      mTypeOfService: "Members' Resolutions",
      mInnerDetails: "",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 120"
    },
    {
      entityType: "All Companies with Shares",
      typeOfService: "Allotment and Issuance of Shares",
      mTypeOfService: "Allotment and Issuance of Shares",
      mInnerDetails: "",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 360"
    },
    {
      entityType: "All Companies with Shares",
      typeOfService: "Transfer of Shares",
      mTypeOfService: "Transfer of Shares",
      mInnerDetails: "",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 360"
    },
    {
      entityType: "All Companies with Shares",
      typeOfService: "Share Capital Alteration",
      mTypeOfService: "Share Capital Alteration",
      mInnerDetails: "",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 1,600"
    },
    {
      entityType: "All Companies with Shares",
      typeOfService: "Share Capital Reduction",
      mTypeOfService: "Share Capital Reduction",
      mInnerDetails: "",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 3,600"
    },
    {
      entityType: "All Companies with Shares",
      typeOfService: "Share Buyback",
      mTypeOfService: "Share Buyback",
      mInnerDetails: "",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 3,600"
    },
    {
      entityType: "All Companies with Shares",
      typeOfService: "Conversion of Shares",
      mTypeOfService: "Conversion of Shares",
      mInnerDetails: "",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 2,200"
    },
    {
      entityType: "All Companies with Shares",
      typeOfService: "Declaration of Dividends (Interim or Final)",
      mTypeOfService: "Declaration of Dividends",
      mInnerDetails: " Both Interim or Final",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 240"
    },
    {
      entityType: "All Companies",
      typeOfService: "Declaration of Directors' Fees",
      mTypeOfService: "Declaration of Directors' Fees",
      mInnerDetails: "",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 240"
    },
    {
      entityType: "All Entities",
      typeOfService: "Opening or Closing of Entity Bank Account",
      mTypeOfService: "Opening/Closing of Bank Account",
      mInnerDetails: "",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 360"
    },
    {
      entityType: "All Entities",
      typeOfService: "Addition or Changes in Authorised Signatories of Entity Bank Account",
      mTypeOfService: "Add/Change Authorised Signatories of Bank Account",
      mInnerDetails: "",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 240"
    }
  ];