import React from 'react';
import {
    Box,
    Flex,
    Text,
    Link,
    Stack,
    HStack,
    IconButton,
    useColorModeValue,
    Button,
    VStack,
    Icon,
} from '@chakra-ui/react';
import { 
    FaLinkedin,
    FaInstagram,
    FaTiktok,
    FaFacebook 
} from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { MdLocationOn } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const bg = useColorModeValue('#7201a9', 'white');
    const color = useColorModeValue('white', '#7201a9');
    const termsUrl = "https://www.example.com/terms";
    const addressUrl = "https://www.google.com/maps/place/Sequence+SG+BizCo+Pte.+Ltd./@1.2784458,103.8460723,17z/data=!4m6!3m5!1s0x31da19c8abcde7db:0xc7f550de873dc214!8m2!3d1.2784458!4d103.848261!16s%2Fg%2F11y4zygnmg?entry=ttu";
    const address1 = "Sequence SG BizCo Pte. Ltd.";
    const address2 = "UEN 202346260R  |  FA 20230485";
    const address3 = "140 Robinson Road, #09-02,";
    const address4 = " Tahir Building, Singapore 068907";
    const pipeSymbol = " | ";
    const navigate = useNavigate();
    return (
        <Box
            as="footer"
            role="contentinfo"
            py={3}
            px={8}
            bg={bg}
            color={color}>
            <VStack align="center">
                <VStack align="center">
                    {/* <Stack direction="row" spacing={6}>
                        <Link href="https://linkedin.com" isExternal>
                            <IconButton
                                icon={<FaLinkedin />}
                                isRound
                                variant="ghost"
                                aria-label="LinkedIn"
                                color="current"
                            />
                        </Link>
                        <Link href="https://instagram.com" isExternal>
                            <IconButton
                                icon={<FaInstagram/>}
                                isRound
                                variant="ghost"
                                aria-label="Instagram"
                                color="current"
                            />
                        </Link>
                        <Link href="https://tiktok.com" isExternal>
                            <IconButton
                                icon={<FaTiktok />}
                                isRound
                                variant="ghost"
                                aria-label="TikTok"
                                color="current"
                            />
                        </Link>
                        <Link href="https://x.com" isExternal>
                            <IconButton
                                icon={<FaSquareXTwitter/>}
                                isRound
                                variant="ghost"
                                aria-label="x"
                                color="current"
                            />
                        </Link>
                        <Link href="https://facebook.com" isExternal>
                            <IconButton
                                icon={<FaFacebook  />}
                                isRound
                                variant="ghost"
                                aria-label="Facebook"
                                color="current"
                            />
                        </Link>
                    </Stack> */}
                    <HStack display={{ base: "none", sm:"none", md: "flex", lg: "flex", xl: "flex" }}>
                        {/* <Link href={termsUrl} isExternal>
                            <Button onClick={()=>{}} variant="link" color='#ffffff'> Contact Us </Button>
                        </Link>
                        <Text ml="10px" mr="10px" color='#ffffff'>|</Text> */}
                        <Link>
                            <Button onClick={()=>{navigate("/tos")}}  variant="link" color='#ffffff'> terms of service </Button>
                        </Link>                
                        <Text ml="10px" mr="10px" color='#ffffff'>|</Text>
                        <Link>
                            <Button onClick={()=>{navigate("/dpp")}}  variant="link" color='#ffffff'> data protection policy  </Button>
                        </Link>
                        {/* <Text ml="10px" mr="10px">|</Text>
                        <Link href={termsUrl} isExternal>
                            <Button onClick={()=>{}}  variant="link" color='#ffffff'> Sequence Knowledge Base </Button></Link>
                        <Text ml="10px" mr="10px" color='#ffffff'>|</Text>
                        <Link href={termsUrl} isExternal>
                            <Button onClick={()=>{}}  variant="link" color='#ffffff'> Sequence News </Button>
                        </Link> */}
                    </HStack>
                    <VStack display={{ base: "flex", sm:"flex", md: "none", lg: "none", xl: "none" }}>
                        {/* <Link href={termsUrl} isExternal>
                            <Button onClick={()=>{}} variant="link" color='#ffffff'> Contact Us </Button>
                        </Link> */}
                        <Link>
                            <Button onClick={()=>{navigate("/tos")}}  variant="link" color='#ffffff' textDecoration="underline" > terms of service </Button>
                        </Link>                
                        <Link>
                            <Button onClick={()=>{navigate("/dpp")}}  variant="link" color='#ffffff' textDecoration="underline"> data protection policy </Button>
                        </Link>
                        {/*
                        <Link href={termsUrl} isExternal>
                            <Button onClick={()=>{}}  variant="link" color='#ffffff'> Sequence Knowledge Base </Button>
                        </Link>
                        <Link href={termsUrl} isExternal>
                            <Button onClick={()=>{}}  variant="link" color='#ffffff'> Sequence News </Button>
                        </Link> */}
                    </VStack>
                </VStack>
                <Flex align="center" display={{ base: "none", sm:"none", md: "flex", lg: "flex", xl: "flex" }}>
                    <Icon as={MdLocationOn} w={5} h={5} color="#ffffff" mr={2} />
                    <Link href={addressUrl} isExternal>
                            <Button onClick={()=>{}} variant="link" size="sm" color='#ffffff'>
                                {address1 + pipeSymbol + address2 + pipeSymbol + address3 + address4}
                            </Button>
                        </Link>
                </Flex>
                <Link href={addressUrl} isExternal display={{ base: "flex", sm:"flex", md: "none", lg: "none", xl: "none" }}>
                    <VStack align="center">
                        <Icon as={MdLocationOn} w={5} h={5} color="#ffffff" mr={2}/>
                        <Text fontSize={"xs"}>{address1}</Text>
                        <Text fontSize={"xs"}>{address2}</Text>
                        <Text fontSize={"xs"}>{address3}</Text>
                        <Text fontSize={"xs"}>{address4}</Text>
                    </VStack>
                </Link>
            </VStack>
        </Box>
    );
};

export default Footer;
