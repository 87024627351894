import React, { useRef } from 'react';
import { 
    Box,
    Card,
    Center,
    Flex, 
    Grid, 
    GridItem, 
    HStack, 
    Icon, 
    Image, 
    ListItem, 
    Text,
    UnorderedList,
    VStack
} from '@chakra-ui/react';
import Footer from '../LandingPage/Footer.js';
import HeaderExp from '../experiment/HeaderExp';
import FAQ from '../LandingPage/FAQ.js';
import { MdStar } from "react-icons/md";

import { COMPANY_LOGOS, ECO_SYSTEM_LOGOS, OSOME_BEIGE_COLOR, PRIMARY_COLOR, SERVICES_DETAILS, SOLAR_BANNER_BA, SOLAR_BANNER_L } from '../constant.js';
import { UpdatedSeqService } from '../experiment/UpdatedSeqService.js';
import { chunkArray } from '../LandingPage/Utilities/utilities.js';

function Home() {
    const itemRefs = useRef([]);
    const ecoList = useRef(chunkArray(ECO_SYSTEM_LOGOS,3));
    const compList = useRef(chunkArray(COMPANY_LOGOS,3));

    const handleScrollToItem = (index) => {
        if (itemRefs.current[index]) {
            itemRefs.current[index].scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <Box>
            <HeaderExp handleScrollToItem={(index) => {handleScrollToItem(index)}}/>
            <Flex h="980px" mt="80px"  bgImage={{ base: SOLAR_BANNER_BA, sm: SOLAR_BANNER_BA, md: SOLAR_BANNER_L, lg: SOLAR_BANNER_L, xl: SOLAR_BANNER_L}} justify={"center"}  bgRepeat="no-repeat" bgPosition="center" bgSize="cover">
                <Flex mt="150px">
                    <VStack mt={{base:"40px"}}>
                        <Text fontSize={{ base: "30px", md: "50px", lg: "70px", xl: "90px" }} color="white" fontFamily="Moderustic" p="5px" borderRadius={"15px"}> modern day compliance </Text>
                        {/* <Text fontSize={{ base: "20px", md: "40px", lg: "60px", xl: "80px" }}  color="white" fontFamily="Moderustic"> we take care of the rest </Text> */}
                    </VStack>
                </Flex>
            </Flex>
            <Flex direction="column" bg={OSOME_BEIGE_COLOR}>
                <Text fontSize={{ base: "30px", sm:"40px", md: "50px", lg: "60px", xl: "50px" }} color={PRIMARY_COLOR} fontFamily="Moderustic" mt="30px"> our services </Text>
                <Box>{SERVICES_DETAILS.map((element, index) => (<Box ref={(el) => (itemRefs.current[index] = el)} scrollMarginTop={90}><UpdatedSeqService element={element} index={index} key={index}/></Box>))}</Box>
            </Flex>
            <Flex h={{ base: "750px", sm:"950px", md: "1350px", lg: "450px", xl: "450px" }} direction="column">
                <Text fontSize={{ base: "30px", sm:"30px", md: "30px", lg: "40px", xl: "40px" }} color="black" fontFamily="Moderustic" mt="30px"> trusted by our clients </Text>
                <Center>
                    <Flex justify={"center"} w="1281px" css={{'&::-webkit-scrollbar': {
                        display: 'none'
                    },
                    '-ms-overflow-style': 'none',
                    'scrollbar-width': 'none',
                    }} mt="10px" overflowX={{ base: "auto"}} whiteSpace="nowrap" display={{ base: "none", sm:"none", md: "none", lg: "flex", xl: "flex" }}>
                        <HStack>
                            <>{
                                COMPANY_LOGOS.map((element,index) => {
                                    return(
                                        <Card boxShadow="0 0 0px rgba(0, 0, 0, 0.25)" mx="15px" h={{ base: "70px", md: "80px", lg: "90px", xl: "100px" }} w={{ base: "70px", md: "80px", lg: "90px", xl: "100px" }}>
                                            <Image src={element} alt='SP' boxSize='100%' objectFit='contain' p="10px"/>
                                        </Card>
                                    )
                                })
                            }</>
                        </HStack>
                    </Flex>
                </Center>
                <VStack w="100%" p="25px" display={{ base: "flex", sm:"flex", md: "flex", lg: "none", xl: "none" }}>
                    <>{
                        compList.current.map((element,index) => {
                            return(
                                
                                    <HStack w="100%">
                                        <Center>
                                        <>{
                                            element.map((subElement, subIndex) => {
                                                return(
                                                <Flex w="33%">
                                                    <Image src={subElement} alt='SP' boxSize='90%' objectFit='contain' p="10px"/>
                                                </Flex>
                                                )
                                            })
                                        }</>
                                      </Center>  
                                    </HStack>
                                
                            )
                        })
                    }</>
                </VStack>
                <Text fontSize={{ base: "30px", sm:"30px", md: "30px", lg: "40px", xl: "40px" }} color="black" fontFamily="Moderustic" mt="40px"> part of the wider ecosystem </Text>
                <Center>
                    <Flex justify={"center"} css={{'&::-webkit-scrollbar': {
                        display: 'none'
                    },
                    '-ms-overflow-style': 'none',
                    'scrollbar-width': 'none',
                    }} mt="10px" overflowX={{ base: "auto"}} display={{ base: "none", sm:"none", md: "none", lg: "flex", xl: "flex" }}>
                        <HStack>
                            <>{
                                ECO_SYSTEM_LOGOS.map((element,index) => {
                                    return(
                                        <Card boxShadow="0 0 0px rgba(0, 0, 0, 0.25)" mx="15px" h={{ base: "70px", md: "80px", lg: "90px", xl: "100px" }} w={{ base: "70px", md: "80px", lg: "90px", xl: "100px" }}>
                                            <Image src={element} alt='SP' boxSize='100%' objectFit='contain' p="10px"/>
                                        </Card>
                                    )
                                })
                            }</>
                        </HStack>
                    </Flex>
                </Center>
                <VStack w="100%" p="25px" display={{ base: "flex", sm:"flex", md: "flex", lg: "none", xl: "none" }}>
                    <>{
                        ecoList.current.map((element,index) => {
                            return(
                                    <HStack w="100%" key={index}>
                                        <Center>
                                        <>{
                                            element.map((subElement, subIndex) => {
                                                return(
                                                <Flex w="33%" key={index + subIndex}>
                                                    <Image src={subElement} alt='SP' boxSize='90%' objectFit='contain' p="10px" />
                                                </Flex>
                                                )
                                            })
                                        }</>
                                      </Center>  
                                    </HStack>
                                
                            )
                        })
                    }</>
                </VStack>
            </Flex>
            {/* <Flex direction="column" mt="50px" bg="white" alignItems="stretch" pb="150px">
                <Flex justify="center">                            
                    <Text fontSize={{ base: "2xl", sm:"3xl", md: "5xl", lg: "5xl", xl: "5xl" }} mt="10px" color="black" fontFamily="Moderustic" as='b'>Frequently Asked Questions</Text>
                </Flex>
                <Flex w={{ base: "100%", sm:"100%", md: "100%", lg: "100%", xl: "100%" }} p="25px">
                    <FAQ/>
                </Flex>
            </Flex> */}
            <Box display="flex" flexDirection="column" justifyContent="space-between">
                <Footer />
            </Box>
        </Box>
  );
}

export default Home;