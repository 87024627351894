import React from 'react';
import { Box, Button, Center, Flex, HStack, Icon, Image, Card, Text, VStack } from '@chakra-ui/react';
import { THIRD_BG_SHADE_COLOR, PRIMARY_COLOR, PRIMARY_FONT, SECONDARY_BG_SHADE_COLOR } from '../constant';
import { useNavigate } from 'react-router-dom';

export const UpdatedSeqService = (props) => {
    const { element, index } = props;
    const {
        image,
        title,
        pricingPath,
        primaryDetail,
        isNoPricing
    } = element;

    const navigate = useNavigate();

    return (
        <>
        <Center>
        <Card direction="row" alignItems="stretch" bg={THIRD_BG_SHADE_COLOR} fontFamily={PRIMARY_FONT} w="1281px" my="20px" boxShadow="0 0 20px rgba(0, 0, 0, 0.25)" display={{base: "none", sm:"none",md: "none", lg: "flex", xl: "flex"}}>
            {(index % 2 === 0)?<Flex w="60%">
                <Image src={image} alt='SP' boxSize='100%' objectFit='contain' bg={SECONDARY_BG_SHADE_COLOR} p="20px"/>
            </Flex>:null}
            <Flex direction="column" w="40%" justifyContent={"center"} px="20px">
                <Flex>                            
                    <Text fontSize={{base: "30px", sm:"30px",md: "40px", lg: "40px", xl: "30px"}} mt="10px" color="black" as='b' p="10px">{title}</Text>
                </Flex>
                <Flex>                            
                    <Text textAlign={"justify"} key={index} p="10px" color="black">
                        {primaryDetail}
                    </Text>
                </Flex>
                {!isNoPricing?<Flex justify="center" my="20px">
                    <Button w="200px" bg="transparent" _hover={{bg:PRIMARY_COLOR, color:"#ffffff"}} color="black" border={"1px"} borderRadius={"5px"} borderColor={PRIMARY_COLOR} boxShadow="0 0 20px rgba(0, 0, 0, 0.25)" onClick={() => {navigate(pricingPath)}}>View Pricing</Button>
                </Flex>:null}
            </Flex>
            {(index % 2 !== 0)?<Flex w="60%">
                <Image src={image} alt='SP' boxSize='100%' objectFit='contain' bg={SECONDARY_BG_SHADE_COLOR} p="20px"/>
            </Flex>:null}
        </Card>
        </Center>
        <Card alignItems="stretch" bg={THIRD_BG_SHADE_COLOR} fontFamily={PRIMARY_FONT} my="20px" mx="10px" boxShadow="0 0 20px rgba(0, 0, 0, 0.25)" display={{base: "flex", sm:"flex",md: "flex", lg: "none", xl: "none"}}>
            <Flex direction="column" w="100%" justifyContent={"center"} px="20px">
                <Flex>                            
                    <Text fontSize={{base: "30px", sm:"30px",md: "40px", lg: "40px", xl: "30px"}} mt="10px" color="black" as='b' p="10px">{title}</Text>
                </Flex>
                <Flex>                            
                    <Text textAlign={"justify"} key={index} px="10px" pb="10px" color="black">
                        {primaryDetail}
                    </Text>
                </Flex>
            </Flex>
            <Flex w="100%"  direction="column" bg={SECONDARY_BG_SHADE_COLOR}>
                <Image src={image} alt='SP' boxSize='100%' objectFit='contain' bg={SECONDARY_BG_SHADE_COLOR} p="20px"/>
                {!isNoPricing?<Flex justify="center" my="20px">
                    <Button w="200px" bg="transparent" _hover={{bg:PRIMARY_COLOR, color:"#ffffff"}} color="black" border={"1px"} borderRadius={"5px"} borderColor={PRIMARY_COLOR} boxShadow="0 0 20px rgba(0, 0, 0, 0.25)" onClick={() => {navigate(pricingPath)}}>View Pricing</Button>
                </Flex>:null}
            </Flex>
        </Card>
        </>
    )
}
