export const AA_PRICING = [
    {
      entityType: "All Entities",
      typeOfService: "XBRL Filing for Financial Statements",
      mTypeOfService: "XBRL Filing for Financial Statements",
      mInnerDetails: "",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 650"
    },
    {
      entityType: "All Entities",
      typeOfService: "Setting up of Accounting systems (including Chart of Accounts and other processes)",
      mTypeOfService: "Setting up of Accounting systems",
      mInnerDetails: "including Chart of Accounts and other processes",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 0"
    },
    {
      entityType: "All Entities",
      typeOfService: "Transfer In from other Corporate Service Providers",
      mTypeOfService: "Transfer In from other Corporate Service Providers",
      mInnerDetails: "",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 0"
    },
    {
      entityType: "All Entities",
      typeOfService: "Bookkeeping and Updated Monthly Unaudited Financial Statements and Unaudited Annual Financial Statements (as per FYE)",
      mTypeOfService: "Bookkeeping and Unaudited Financial Statements",
      mInnerDetails: "Including Updated Monthly Unaudited Financial Statements and Unaudited Annual Financial Statements (as per FYE)",
      unitOfMeasure: "Per Accounting Transaction",
      totalPrice: "S$ 3"
    },
    {
      entityType: "All Entities",
      typeOfService: "Consolidation of Group Accounts",
      mTypeOfService: "Consolidation of Group Accounts",
      mInnerDetails: "",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 1,500"
    },
    {
      entityType: "All Entities",
      typeOfService: "Audit of Financial Statements",
      mTypeOfService: "Audit of Financial Statements",
      mInnerDetails: "",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 6,000"
    },
    {
      entityType: "All Entities",
      typeOfService: "GST Registration with IRAS",
      mTypeOfService: "GST Registration with IRAS",
      mInnerDetails: "",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 600"
    },
    {
      entityType: "All Entities",
      typeOfService: "Quarterly GST Filing with IRAS",
      mTypeOfService: "Quarterly GST Filing with IRAS",
      mInnerDetails: "",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 360"
    },
    {
      entityType: "All Companies",
      typeOfService: "Corporate Income Tax: Computation and Filing with IRAS for Estimated Chargeable Income (ECI)",
      mTypeOfService: "Corporate Income Tax - ECI",
      mInnerDetails: "Computation and Filing with IRAS for Estimated Chargeable Income (ECI)",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 600"
    },
    {
      entityType: "All Companies",
      typeOfService: "Corporate Income Tax: Computation and Filing with IRAS for Form C, Form C-S, or Form C-S (Lite)",
      mTypeOfService: "Corporate Income Tax - Form C, Form C-S, or Form C-S (Lite)",
      mInnerDetails: "Computation and Filing with IRAS for Form C, Form C-S, or Form C-S (Lite)",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 800"
    },
    {
      entityType: "All Entities",
      typeOfService: "Tax Consultancy",
      mTypeOfService: "Tax Consultancy",
      mInnerDetails: "",
      unitOfMeasure: "Per Transaction",
      totalPrice: "S$ 16,000"
    }
  ]