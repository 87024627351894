import { Box } from "@chakra-ui/react";
import { Worker, Viewer  } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

function PDFViewer(props) {
    const {
        file
    } = props;
  return (
    <Box p={5} h="100%" w="100%">
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <Viewer fileUrl={file}/>
        </Worker>
    </Box>
  );
}

export default PDFViewer;
