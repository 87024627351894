import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../routes/Home';
import AppPage from '../routes/AppPage';
import { 
    ChakraProvider 
} from '@chakra-ui/react';
import Pricing from '../routes/Pricing';

function SeqHome(props) {
    return (
            <Routes>
                <Route path="/" exact element={<Home/>}/>
            </Routes>
    );
}

export default SeqHome;