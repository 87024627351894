import React, { useEffect, useState } from 'react';
import { Box, Center, Heading, VStack, Text, OrderedList, ListItem } from '@chakra-ui/react';
import { PRIMARY_FONT } from '../constant';
import HeaderExp from '../experiment/HeaderExp';
import Footer from '../LandingPage/Footer';
import PDFViewer from '../experiment/PDFViewer';
import { useLocation } from 'react-router-dom';
import sequence_tos from '../assets/sequence_tos.pdf'; 
import sequence_dpp from '../assets/sequence_dpp.pdf';

function DocumentView(props) {
  const location = useLocation();
  const [file, setFile] = useState(sequence_tos);
  useEffect(() => {
    if(location.pathname === "/tos"){
      setFile(sequence_tos);
    }else if(location.pathname === "/dpp"){
      setFile(sequence_dpp);
    }
  },[location]);

  return (
    <Box>
      <HeaderExp showMenu={false}/>
      <Center>
        <VStack w="1281px" display={{base: "none", sm:"none",md: "none", lg: "flex", xl: "flex"}} fontFamily={PRIMARY_FONT} h="100%"  mb="30px" mt="100px">
          <PDFViewer file={file}/>
        </VStack>
      </Center>
      <VStack display={{base: "flex", sm:"flex",md: "flex", lg: "none", xl: "none"}} fontFamily={PRIMARY_FONT}  mb="30px" mt="70px">
        <PDFViewer file={file} mx="0px"/>
      </VStack>
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Footer />
      </Box>
    </Box>
  )
}

export default DocumentView;
