import React, { useState } from 'react';
import { Box, Flex, HStack, Button, Link, IconButton, useDisclosure, Stack, Heading, Icon, Text, Center, Tooltip, useToast } from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import Login from '../LandingPage/Access/Login';
import Signup from '../LandingPage/Access/Signup';
import { RiLoginBoxLine , } from "react-icons/ri";
import { SiGnuprivacyguard } from "react-icons/si";
import { FaWhatsapp } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import { MdOutlineEmail } from "react-icons/md";
import MenuBar from '../LandingPage/MenuBar';
import { PRIMARY_COLOR, WHATSAPP_GREEN_COLOR } from '../constant';
import ContactUs from './ContactUs';
import { BiLeftArrow } from 'react-icons/bi';

function HeaderExp(props) {
  const {
    showMenu = true,
    handleScrollToItem
  } = props;
  const navigate = useNavigate();
  const toast = useToast();

  const goToAppPage = () => {
    navigate('/appPage');
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);
  const [isContactOpen, setIsContactOpen] = useState(false);
  const [menuItems, setMenuItems] = useState({show:false,name:""});
  const Whatsappurl = `https://wa.me/+6591785041`;


  const whatsAppOpen = () => {
    window.open(Whatsappurl, '_blank');
  };

  return (
    <Box bg="white"
    position="fixed"
    top={0}   
    width="100%"
    px={4}
    py={2}
    boxShadow="md"
    zIndex="1000">
      <Flex h={16} alignItems="center" justifyContent="space-between" ml="10px">
        <Box>
          <Text as="h1" fontSize="30px" color="#7201a9" fontFamily="Moderustic" onClick={()=>{navigate("/")}} _hover={{cursor:"pointer"}}>
            sequence
          </Text>
        </Box>
        {/* Menu Bar */}
        {showMenu?<MenuBar  setMenuItems={(value) => {setMenuItems(value)}} handleScrollToItem={handleScrollToItem}/>:null}

        <Flex>
          <HStack mx="20px" display={{ base: 'none', md: 'flex' }}>
            {/* <Button bg="white" color="#7201a9" mr="10px" _hover={{ bg: '#7201a9', color:'white' }} onClick={()=>{setIsLoginOpen(true)}} rightIcon={<Icon as={RiLoginBoxLine}/>} fontFamily="Moderustic"> Login </Button>
            <Button bg="#7201a9" color="white" _hover={{ bg: '#7201a9' }} onClick={()=>{setIsSignUpOpen(true)}} rightIcon={<Icon as={SiGnuprivacyguard}/>} fontFamily="Moderustic"> Sign up </Button> */}
            {/* <Login isOpen={isLoginOpen} onOpen={() => {setIsLoginOpen(true)}} onClose={() => {setIsLoginOpen(false)}}/>
            <Signup isOpen={isSignUpOpen} onOpen={() => {setIsSignUpOpen(true)}} onClose={() => {setIsSignUpOpen(false)}}/>       */}
            {(!showMenu)?<Button bg="white" color={PRIMARY_COLOR} mr="10px" _hover={{ bg: PRIMARY_COLOR, color:'white' }} onClick={()=>{navigate('/')}} leftIcon={<Icon as={BiLeftArrow}/>}> Home </Button>:null}
          </HStack>

          <Box h="100%" w="10%" mt="5px" display={{ base: 'none', md:'none', lg:'flex'}}>
              <Center>
              <Tooltip label={<Text>info@sequence.sg</Text>}>
                <Button rightIcon={<MdOutlineEmail/>}
                    aria-label="Email"
                    variant="outline"
                    color={PRIMARY_COLOR}
                    size="sm" ml="5px" mr="5px" onClick={() => {setIsContactOpen(true)}}>Contact Us</Button>
              </Tooltip>
              <ContactUs isOpen={isContactOpen} setIsContactOpen={(flag) => {setIsContactOpen(flag)}}/>
              <Tooltip label={<Text>WhatsApp</Text>}>
                <IconButton icon={<FaWhatsapp/>}
                    aria-label="Whatsapp"
                    variant="outline"
                    size="sm" ml="5px" mr="5px"
                    color={WHATSAPP_GREEN_COLOR}
                    onClick={() => {whatsAppOpen()}}/>
              </Tooltip>
              <Tooltip label={<Text>Click to copy +65 9178 5041 to clipboard</Text>}>
                <IconButton icon={<IoIosCall/>}
                  aria-label="Call"
                  variant="outline"
                  color={WHATSAPP_GREEN_COLOR}
                  size="sm" ml="5px" mr="5px" onClick={() => {navigator.clipboard.writeText("+65 9178 5041"); 
                    toast({
                    title: 'Success',
                    description: "+65 9178 5041 phone number copied to clipboard",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                  })}}/>
              </Tooltip>
              </Center>
          </Box>

          {/* Mobile Menu Button */}
          <IconButton
            size="md"
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label="Open Menu"
            display={{ md: 'flex', lg:"none" }}
            color={PRIMARY_COLOR}
            onClick={isOpen ? onClose : onOpen}/>
        </Flex>
      </Flex>

      {/* Mobile Menu */}
      {isOpen ? (
        <Box pb={4} display={{ md: 'flex',lg:'flex' }}>
          <Stack as="nav" spacing={4}>
          {(showMenu)?<><Link color={PRIMARY_COLOR} fontFamily="Moderustic" onClick={() =>{onClose(); handleScrollToItem(0)}}>Corporate Secretarial</Link>
            <Link color={PRIMARY_COLOR} fontFamily="Moderustic"onClick={() =>{onClose(); handleScrollToItem(1)} }>Accounting & Audit</Link>
            <Link color={PRIMARY_COLOR} fontFamily="Moderustic"onClick={() =>{onClose(); handleScrollToItem(2)}}>HR Management</Link>
            <Link color={PRIMARY_COLOR} fontFamily="Moderustic" onClick={() =>{onClose(); handleScrollToItem(3)}}>Business Consultancy</Link></>:null}
            {(!showMenu)?<Button bg="white" color={PRIMARY_COLOR} mr="10px" _hover={{ bg:{PRIMARY_COLOR}, color:'white' }} onClick={()=>{navigate("/")}} rightIcon={<Icon as={BiLeftArrow} color={PRIMARY_COLOR}/>}> Home </Button>:null}
            <Box h="100%" w="100%" mt="5px">
              <Center>
              <Tooltip label={<Text>info@sequence.sg</Text>}>
                <Button rightIcon={<MdOutlineEmail/>}
                    aria-label="Email"
                    variant="outline"
                    color={PRIMARY_COLOR}
                    size="sm" ml="5px" mr="5px" onClick={() => {setIsContactOpen(true)}}>Contact Us</Button>
              </Tooltip>
              <ContactUs isOpen={isContactOpen} setIsContactOpen={(flag) => {setIsContactOpen(flag)}}/>
              <Tooltip label={<Text>WhatsApp</Text>}>
                <IconButton icon={<FaWhatsapp/>}
                    aria-label="Whatsapp"
                    variant="outline"
                    color={WHATSAPP_GREEN_COLOR}
                    size="sm" ml="5px" mr="5px"
                    onClick={() => {whatsAppOpen()}}/>
              </Tooltip>
              <Tooltip label={<Text>Click to copy +65 9178 5041 to clipboard</Text>}>
                <IconButton icon={<IoIosCall/>}
                  aria-label="Call"
                  variant="outline"
                  color={WHATSAPP_GREEN_COLOR}
                  size="sm" ml="5px" mr="5px" onClick={() => {navigator.clipboard.writeText("+65 9178 5041");toast({
                    title: 'Success',
                    description: "+65 9178 5041 phone number copied to clipboard",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                  })}}/>
              </Tooltip>
              </Center>
            </Box>
            {/* <Link href="#faq" color={PRIMARY_COLOR} fontFamily="Moderustic" onClick={() =>{onClose(); scrollToTarget(4)}}>FAQs</Link> */}
            {/* <Button bg="white" color="#2b6cb0" mr="10px" _hover={{ bg: 'blue', color:'white' }} onClick={()=>{setIsLoginOpen(true)}} rightIcon={<Icon as={RiLoginBoxLine}/>}> Login </Button>
            <Button bg="blue" color="white" _hover={{ bg: '#2F4F4F' }} onClick={()=>{setIsSignUpOpen(true)}} rightIcon={<Icon as={SiGnuprivacyguard}/>}> Sign up </Button> */}
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
}

export default HeaderExp;
