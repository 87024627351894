export const EMPTY = "";
export const LOGIN = "Login";
export const SUBMIT = "Submit";
export const CHECK_IN = "Check In";

//Checkin Submit state
export const EMAIL_EDIT = "Editing Email";
export const PASSWORD_EDIT = "Editing Password";
export const GETTING_PASSWORD = "Getting Password";

//options

export const OPTIONS = [PASSWORD_EDIT, GETTING_PASSWORD];


